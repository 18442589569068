import { getKeyByValue, getKeyByValueWithoutDates } from './object';
export const getEnglishErrorMessageByMessageText = (message) => {
    const translations = {
        en: require('@/translations/store_locator.en.json'),
        et: require('@/translations/store_locator.et.json'),
        ru: require('@/translations/store_locator.ru.json'),
    };
    let errorEtKey = getKeyByValue(translations.et, message);
    let errorRuKey = getKeyByValue(translations.ru, message);
    let messageEt;
    let messageRu;
    if (!errorEtKey && !errorRuKey) {
        messageEt = getKeyByValueWithoutDates(translations.et, message);
        messageRu = getKeyByValueWithoutDates(translations.ru, message);
        errorEtKey = messageEt?.key;
        errorRuKey = messageRu?.key;
    }
    if (!errorEtKey && !errorRuKey) {
        return message;
    }
    if (errorEtKey && messageEt?.date?.length === 1 && messageEt?.variables?.length === 1) {
        return translations.en[errorEtKey].replace(messageEt.variables[0], messageEt.date[0]);
    }
    if (errorRuKey && messageRu?.date?.length === 1 && messageRu?.variables?.length === 1) {
        return translations.en[errorRuKey].replace(messageRu.variables[0], messageRu.date[0]);
    }
    // @ts-ignore
    return translations.en[errorEtKey] || translations.en[errorRuKey];
};
